<template>
  <nav class="navbar navbar-expand-lg navbar-dark border-bottom">
    <div class="container-fluid d-flex">
      <RouterLink class="navbar-brand d-flex align-items-center" to="/">
        <!-- <img :src="require(`@/assets/images/logo.png`)" alt="Logo" width="84" height="29"
          class="d-inline-block align-text-top"> -->
          <Logo />
        <span class="ms-2 fw-bold text-wrapper-1">Premium Auto Rentals</span>
      </RouterLink>
      <button class="navbar-toggler " type="button" @click="toggleMenu" aria-controls="navbarNav" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div :class="['collapse', 'navbar-collapse', 'justify-content-end', 'gap-3', { show: menuOpen }]" id="navbarNav"
        @click.away="menuOpen = false">
        <ul class="navbar-nav">
          <!-- <li class="nav-item">
            <a class="nav-link scroll" href="#featured_cars">Featured Cars</a>
          </li> -->
          <li class="nav-item">
            <RouterLink class="nav-link scroll" to="/#category">Categories</RouterLink>
            <!-- <a class="nav-link scroll" href="/#category">Categories</a> -->
          </li>
          <li class="nav-item">
            <RouterLink class="nav-link scroll" to="/#top_cars">Top Cars</RouterLink>
            <!-- <a class="nav-link scroll" href="/#top_cars">Top Cars</a> -->
          </li>
          <li class="nav-item">
            <RouterLink class="nav-link scroll" to="/#detailing">Car/SUV Detailing</RouterLink>
            <!-- <a class="nav-link scroll" href="/#top_cars">Top Cars</a> -->
          </li>
          <li class="nav-item">
            <RouterLink class="nav-link scroll" to="/#ceramic-coating">Ceramic Coating</RouterLink>
            <!-- <a class="nav-link scroll" href="/#top_cars">Top Cars</a> -->
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link scroll" href="/#reviews">Reviews</a> -->
            <RouterLink class="nav-link scroll" to="/#reviews">Reviews</RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink class="nav-link scroll" to="/#about_us">About Us</RouterLink>
            <!-- <a class="nav-link scroll " href="/#about_us">About Us</a> -->
          </li>
        </ul>
        <button @click="sendDetail" class="btn btn-danger btn-padding d-flex align-items-center gap-2" type="button">
          <span>Book Now</span>
          <img class="logos-whatsapp-icon" alt="Logos whatsapp icon"
            src="https://c.animaapp.com/W8NNSxwc/img/logos-whatsapp-icon.svg" />
        </button>
      </div>
    </div>
  </nav>
</template>
<script setup>
import { ref } from 'vue';
import { useWhatsApp } from '../../../reuseFun/sendWhatsapp';
import Logo from '../../../components/Icons/Logo.vue';

const { sendDetail } = useWhatsApp();
const menuOpen = ref(false);

const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
};
</script>
<style scoped>
.navbar {
  padding: 12px 40px;
  font-family: "Plus Jakarta Sans", Helvetica;
  background-color: #171717;
  position: relative;
  z-index: 10;
}

.container-fluid {
  padding: 0px !important;
}

.border-bottom {
  border-bottom: 1px solid #E5E8EB;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #F6F6F6 !important;
}

.btn-danger {
  background-color: #961213 !important;
  color: #F6F6F6;

}

.bs-btn-border-color:hover {
  border-color: none !important;
}

bs-btn-border-color {
  border: none !important;
}

.scroll {
  scroll-behavior: smooth;
}

@media (max-width:700px) {
  .btn-padding {
    padding: 5px;
  }
}

@media (max-width:480px) {
  .navbar {
    padding: 12px 20px;
  }

  .text-wrapper-1 {
    font-size: 16px;
  }
}

@media (max-width:991px) {
  .nav-link[href="#about_us"] {
    margin-bottom: 1rem;
    /* or use any other value you prefer */
  }

}
</style>